function logar_chat(isProduction) {
    if (!isProduction) {
        hidden_button();
        return;
    }

    if (localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))) {
        let user = JSON.parse(localStorage.getItem('user'));
        let name = `${user.firstName} ${user.lastName}`;
        let email = user.email;

        var tifluxScriptConfig = document.createElement('script');
        tifluxScriptConfig.id = 'tifluxConfig';
        tifluxScriptConfig.textContent = `
          var organization_token = "5cee0cad0c0bddce8094bb4b11efca51ee27e9f7";
          var icon_color = "#5123e0";
          var init_minimal = true;
          var show_chat_icon = true;
          var name = "${name}";
          var mail = "${email}";
        `;
        tifluxScriptConfig.type = 'text/javascript';
        document.body.appendChild(tifluxScriptConfig);
  
        var tifluxScriptChat = document.createElement('script');
        tifluxScriptChat.id = 'tifluxChat';
        tifluxScriptChat.type = 'text/javascript';
        tifluxScriptChat.src = 'https://public-assets.tiflux.com/chat_widget.js?organization_token=5cee0cad0c0bddce8094bb4b11efca51ee27e9f7';
        document.head.appendChild(tifluxScriptChat);
    } else {
        hidden_button();
    }
}

function hidden_button() {
    var tifluxChat = document.getElementById('tiflux--chat');
    if (!tifluxChat) {
        return;
    }

    tifluxChat.remove();
}

function show_button() {
    var tifluxChat = document.getElementById('tiflux--chat');
    if (!tifluxChat) {
        return;
    }
    tifluxChat.style.display = 'block';
}
